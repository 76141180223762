@tailwind base;
@tailwind components;
@tailwind utilities;

.app-container {
	min-height: 350px;
}

.sidebar {
	a.active {
		div,
		h1 {
			background-color: #34aadc;
		}
	}
}

ion-icon {
	pointer-events: none;
}
